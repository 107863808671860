import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import "../../styles/intro-section.css";
import handshake from "./customerservice.mp4";
import angagelogo from "./intro-section/angage1.webp";
import menAtWork from "./intro-section/banner-1.webp";
import partern1 from "./intro-section/pattern-1.webp";
// import partern2 from "./intro-section/pattern-2.webp";
import partern3 from "./intro-section/pattern-3.webp";

export default function IntroSection() {
  //animating the humberger menu
  const animateMenu = useRef(null);

  const [isNavItemClicked, setIsNavItemClicked] = useState(false);
  const [isHambergerMenuShown, setIsHambergerMenuShown] = useState(false);

  // animating nav bar section
  const animateNav = useRef(null);
  const animateDesign = useRef(null);
  const animateMenAtWork = useRef(null);
  const animateCta1 = useRef(null);
  const animateCta2 = useRef(null);
  const animateBtn = useRef(null);
  const animateSubtitle = useRef(null);
  const animateIntroSection = useRef(null);
  const animateContactForm = useRef(null);

  const [isContactUsClicked, setIsContactUsClicked] = useState(false);
  const [isContactFormShown, setIsContactFormShown] = useState(false);

  useEffect(() => {
    const tlBorder = gsap.timeline({
      defaults: { duration: 2, ease: "linear" },
    });

    tlBorder.from(animateNav.current, { y: "-100%", opacity: 0 });
    tlBorder.from(
      animateDesign.current,
      1.5,
      { x: "-100%", rotate: 360, opacity: 0 },
      "<50%"
    );
    tlBorder.from(
      animateMenAtWork.current,
      1.5,
      { x: "-20%", opacity: 0 },
      "<50%"
    );
    tlBorder.from(
      animateCta1.current,
      {
        opacity: 0,
        x: "-10%",
        fontSize: "200rem",
      },
      "<"
    );
    tlBorder.from(
      animateCta2.current,
      {
        opacity: 0,
        x: "-10%",
        fontSize: "200rem",
      },
      "<50%"
    );
    tlBorder.from(
      animateSubtitle.current,
      {
        opacity: 0,
        x: "-10%",
      },
      "<50%"
    );
    tlBorder.from(
      animateBtn.current,
      {
        opacity: 0,
        y: "100%",
      },
      "<"
    );
  }, []);

  //animaite intro section
  useEffect(() => {
    if (isContactUsClicked) {
      gsap.to(animateIntroSection.current, { x: "50%" });
      gsap.set(animateContactForm.current, { left: "0%" });
    } else {
      gsap.to(animateIntroSection.current, { x: "0%" });
      gsap.set(animateContactForm.current, { left: "-200%" });
    }
  }, [isContactUsClicked]);

  //animate the menu on mobile
  useEffect(() => {
    if (isNavItemClicked) {
      gsap.to(animateIntroSection.current, { x: "0%" });
      gsap.set(animateMenu.current, { right: "0%" });
    } else {
      gsap.to(animateIntroSection.current, { x: "0%" });
      gsap.set(animateMenu.current, { right: "-200%" });
    }
  }, [isNavItemClicked]);

  // ================================== HANDLE CONTACT US
  function handleContactUs() {
    setIsContactUsClicked(!isContactUsClicked);
    setIsContactFormShown(!isContactFormShown);
  }
  function handleCloseForm() {
    setIsContactUsClicked(!isContactUsClicked);
    setIsContactFormShown(!isContactFormShown);
  }

  function handleCloseHambergerMenu() {
    setIsNavItemClicked(!isNavItemClicked);
    setIsHambergerMenuShown(!isHambergerMenuShown);
  }
  //contacts form
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    productName: "Customer Experience & Support engine",
    message: "",
  });

  //form input focus state
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
  const [isCommentFocused, setIsCommentFocused] = useState(false);

  //validate form inputs
  const [isValidFirstName, setIsValidFirstName] = useState(false);
  const [isValidLastName, setIsValidLastName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  //
  //handle input field value change
  function inputChange(event) {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
    //validating first Name
    if (name === "firstName" && value.length < 3) {
      setIsValidFirstName(true);
    } else {
      setIsValidFirstName(false);
    }
    //validating last name
    if (name === "lastName" && value.length < 3) {
      setIsValidLastName(true);
    } else {
      setIsValidLastName(false);
    }
    //validating email
    if (name === "email") {
      let valid = validateEmail(value);
      if (!valid) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
    //validating phone number
    if (name === "phoneNumber") {
      let valid = validatePhone(value);
      if (!valid) {
        setIsValidPhoneNumber(true);
      } else {
        setIsValidPhoneNumber(false);
      }
    }
  }

  //validate inputs

  //handle firstname input field focus
  function handleChange() {
    //flip is focused truth state
    if (formData.firstName === "") {
      setIsFirstNameFocused((prev) => !prev);
    }
  }
  //handle lastname field focus
  function handleLastNameChange() {
    if (formData.lastName === "") {
      setIsLastNameFocused((prev) => !prev);
    }
  }
  //handle email field focus
  function handleEmailChange() {
    if (formData.email === "") {
      setIsEmailFocused((prev) => !prev);
    }
  }
  //handle phone number field focus
  function handlePhoneNumberChange() {
    if (formData.phoneNumber === "") {
      setIsPhoneNumberFocused((prev) => !prev);
    }
  }
  //comment out this method if u are not using it
  //handle comment field focus
  function handleCommentChange() {
    if (formData.message === "") {
      setIsCommentFocused((prev) => !prev);
    }
  }

  //handle input focus out
  function focusOut() {
    if (formData.firstName === "") {
      setIsFirstNameFocused((prev) => !prev);
    }
  }
  function lastNameFocusOut() {
    if (formData.lastName === "") {
      setIsLastNameFocused((prev) => !prev);
    }
  }
  function emailFocusOut() {
    if (formData.email === "") {
      setIsEmailFocused((prev) => !prev);
    }
  }
  function phoneNumberFocusOut() {
    if (formData.phoneNumber === "") {
      setIsPhoneNumberFocused((prev) => !prev);
    }
  }
  function commentFocusOut() {
    if (formData.message === "") {
      setIsCommentFocused((prev) => !prev);
    }
  }
  //animate input on focus
  //Line
  const start =
    "M0 0.999512C0 0.999512 60.5 0.999512 150 0.999512C239.5 0.999512 300 0.999512 300 0.999512";
  const end =
    "M1 0.999512C1 0.999512 61.5 7.5 151 7.5C240.5 7.5 301 0.999512 301 0.999512";
  //animate first name
  useEffect(() => {
    const tl = gsap.timeline({ defaults: { duration: 1 } });
    if (isFirstNameFocused) {
      tl.fromTo(
        ".elastic-line1",
        { attr: { d: start } },
        { attr: { d: end }, ease: "Power2.easeOut", duration: 0.75 }
      );
      tl.to(
        ".elastic-line1",
        { attr: { d: start }, ease: "elastic.out(3,0.5)" },
        "<50%"
      );
      //Placeholder Shift
      tl.to(
        ".placeholder1",
        {
          top: -15,
          left: 0,
          scale: 0.7,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    } else {
      //Placeholder Shift
      tl.to(
        ".placeholder1",
        {
          top: 0,
          left: 0,
          scale: 1,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    }
  }, [isFirstNameFocused]);

  //animate last name input
  useEffect(() => {
    const tl = gsap.timeline({ defaults: { duration: 1 } });
    if (isLastNameFocused) {
      tl.fromTo(
        ".elastic-line2",
        { attr: { d: start } },
        { attr: { d: end }, ease: "Power2.easeOut", duration: 0.75 }
      );
      tl.to(
        ".elastic-line2",
        { attr: { d: start }, ease: "elastic.out(3,0.5)" },
        "<50%"
      );
      //Placeholder Shift
      tl.to(
        ".placeholder2",
        {
          top: -15,
          left: 0,
          scale: 0.7,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    } else {
      //Placeholder Shift
      tl.to(
        ".placeholder2",
        {
          top: 0,
          left: 0,
          scale: 1,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    }
  }, [isLastNameFocused]);

  //animate email input
  useEffect(() => {
    const tl = gsap.timeline({ defaults: { duration: 1 } });
    if (isEmailFocused) {
      tl.fromTo(
        ".elastic-line-email",
        { attr: { d: start } },
        { attr: { d: end }, ease: "Power2.easeOut", duration: 0.75 }
      );
      tl.to(
        ".elastic-line-email",
        { attr: { d: start }, ease: "elastic.out(3,0.5)" },
        "<50%"
      );
      //Placeholder Shift
      tl.to(
        ".placeholder-email",
        {
          top: -15,
          left: 0,
          scale: 0.7,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    } else {
      //Placeholder Shift
      tl.to(
        ".placeholder-email",
        {
          top: 0,
          left: 0,
          scale: 1,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    }
  }, [isEmailFocused]);

  //animate phone number input
  useEffect(() => {
    const tl = gsap.timeline({ defaults: { duration: 1 } });
    if (isPhoneNumberFocused) {
      tl.fromTo(
        ".elastic-line-phone",
        { attr: { d: start } },
        { attr: { d: end }, ease: "Power2.easeOut", duration: 0.75 }
      );
      tl.to(
        ".elastic-line-phone",
        { attr: { d: start }, ease: "elastic.out(3,0.5)" },
        "<50%"
      );
      //Placeholder Shift
      tl.to(
        ".placeholder-phone",
        {
          top: -15,
          left: 0,
          scale: 0.7,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    } else {
      //Placeholder Shift
      tl.to(
        ".placeholder-phone",
        {
          top: 0,
          left: 0,
          scale: 1,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    }
  }, [isPhoneNumberFocused]);

  //animate comment input
  useEffect(() => {
    const tl = gsap.timeline({ defaults: { duration: 1 } });
    if (isCommentFocused) {
      tl.fromTo(
        ".elastic-line-comment",
        { attr: { d: start } },
        { attr: { d: end }, ease: "Power2.easeOut", duration: 0.75 }
      );
      tl.to(
        ".elastic-line-comment",
        { attr: { d: start }, ease: "elastic.out(3,0.5)" },
        "<50%"
      );
      //Placeholder Shift
      tl.to(
        ".placeholder-comment",
        {
          top: -15,
          left: 0,
          scale: 0.7,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    } else {
      //Placeholder Shift
      tl.to(
        ".placeholder-comment",
        {
          top: 0,
          left: 0,
          scale: 1,
          duration: 0.5,
          ease: "Power2.easeOut",
        },
        "<15%"
      );
    }
  }, [isCommentFocused]);

  //validate email
  function validateEmail(email) {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  //validate phone number
  function validatePhone(phone) {
    let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(phone);
  }

  //handle submit form data
  function handleSubmit(event) {
    event.preventDefault();
    //posting form data

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      contact: formData.phoneNumber,
      productName: formData.productName,
      message: formData.message,
    };

    fetch("https://angage.co.ug/tasksissues/add-from-website", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        response.json();
        alert("Your Inquiry has been sent successfully");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          productName: "Customer Experience & Support engine",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="intro-wrapper" id="introsection">
      <div className="navigation">
        <nav ref={animateNav}>
          <div className="logo-container">
            <a href="#introsection">
              <img src={angagelogo} />
            </a>
            {/* <h2 className="logo">E-SUPPORT</h2> */}
          </div>

          <div className="navigationbuttons">
            <input
              type={"checkbox"}
              className="toggle-menu"
              onClick={handleCloseHambergerMenu}
            ></input>
            <div className="hamburger"></div>

            <ul className="nav-items">
              <li className="itemsss">
                <a
                  href="https://angage.co.ug/support-app#/auth/login"
                  target="_blank"
                >
                  Business Login
                </a>
              </li>

              <li className="items">
                <a
                  href="https://angage.co.ug/support-app#/auth/check-issue-status"
                  target="_blank"
                >
                  Track Issue
                </a>
              </li>

              {/* <li>
                <a href="#Partners">Features stack & Value proposition</a>
              </li> */}

              <li>
                <a href="#ourbackers">Products & Clients</a>
              </li>

              {/* <li>
                <a href="#">Self-Onboarding</a>
              </li> */}

              {/* <li>
                <a href="#">Payments & Licensing</a>
              </li> */}

              {/* <Link to={"#"}>
                <li>
                  <a>Payments & Licensing</a>
                </li>
              </Link> */}
            </ul>

            <ul className="nav-items-mobile" ref={animateMenu}>
              <li className="itemsss">
                <a
                  href="https://angage.co.ug/support-app#/auth/login"
                  target="_blank"
                  onClick={handleCloseHambergerMenu}
                >
                  Business Login
                </a>
              </li>

              <li className="items">
                <a href="#" onClick={handleCloseHambergerMenu}>
                  Track Issue
                </a>
              </li>

              {/* <li>
                <a href="#Partners" onClick={handleCloseHambergerMenu}>
                  Features stack & Value proposition
                </a>
              </li> */}

              <li>
                <a href="#ourbackers" onClick={handleCloseHambergerMenu}>
                  Products & Clients
                </a>
              </li>

              {/* <li onClick={""}>
                <a href="#" onClick={handleCloseHambergerMenu}>
                  Self-Onboarding
                </a>
              </li> */}

              {/* <li>
                <a href="#" onClick={handleCloseHambergerMenu}>
                  Payments & Licensing
                </a>
              </li> */}

              {/* <Link to={"#"}>
                <li>
                  <a>Payments & Licensing</a>
                </li>
              </Link> */}
            </ul>
          </div>
        </nav>
      </div>
      <div ref={animateIntroSection} className="intro-container">
        <div className="overlay"></div>
        <video className="videoBg" autoPlay loop muted>
          <source src={handshake} type="video/mp4" />
        </video>
        <div className="homecontent">
          <img src={partern1} alt="" className="vertical-shades" />

          {/* <img src={partern2} alt="" className="intro-circles" /> */}
          <img src={partern3} alt="" className="intro-horizontal_shades" />
          <img
            ref={animateMenAtWork}
            src={menAtWork}
            alt=""
            className="men-at-work"
          />
        </div>
        <div className="intro-content">
          <div className="content-left">
            <h1 className="content-title">
              <span className="cta">Experience Support</span> <br />
              <span className="cta">Without Limits &#128512;</span>
            </h1>

            <hr
              className="introHr"
              style={{
                borderTop: " 1px solid white",
                borderBottom: " 1px solid white",
              }}
            />
            <button
              ref={animateBtn}
              onClick={handleContactUs}
              className="content-contact_us"
            >
              <div className="background"></div>
              Contact Us
            </button>
          </div>
        </div>
      </div>

      <div ref={animateContactForm} className="contact_us_form">
        <form className="contact-form">
          <div className="form-head">
            <h2 className="form-title">TALK TO US...</h2>
            <div
              onClick={handleCloseForm}
              class="cancel-container"
              id="toggleOne"
            >
              <div class="plus-horiz"></div>
              <div class="plus-vert"></div>
            </div>
          </div>
          <div className="contact-content">
            <div className="contact-right">
              <div className="input-container">
                <p className="placeholder1 placeholder">First Name</p>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  autoComplete="off"
                  required={true}
                  className="input-name input"
                  onFocus={handleChange}
                  onBlur={focusOut}
                  onChange={inputChange}
                />
                <svg
                  className="line-svg"
                  width="300"
                  height="2"
                  viewBox="0 0 300 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="elastic-line1"
                    d="M0 0.999512C0 0.999512 60.5 0.999512 150 0.999512C239.5 0.999512 300 0.999512 400 0.999512"
                    stroke="#D1D4DA"
                    stroke-width="2"
                  />
                </svg>
                {isValidFirstName ? (
                  <span className="errors">
                    First Name must be at least 3 characters long!
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-container">
                <p className="placeholder placeholder2">Last Name</p>
                <input
                  type="text"
                  name="lastName"
                  autocomplete="off"
                  className="input-name input"
                  value={formData.lastName}
                  onFocus={handleLastNameChange}
                  onBlur={lastNameFocusOut}
                  onChange={inputChange}
                />
                <svg
                  className="line-svg"
                  width="300"
                  height="2"
                  viewBox="0 0 300 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="elastic-line2"
                    d="M0 0.999512C0 0.999512 60.5 0.999512 150 0.999512C239.5 0.999512 300 0.999512 400 0.999512"
                    stroke="#D1D4DA"
                    stroke-width="2"
                  />
                </svg>
                {isValidLastName ? (
                  <span className="errors">
                    Last Name must be at least 3 characters long!
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-container">
                <p className="placeholder-email placeholder">Your Email</p>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  autocomplete="off"
                  className="input-email input"
                  onFocus={handleEmailChange}
                  onBlur={emailFocusOut}
                  onChange={inputChange}
                />
                <svg
                  className="line-svg"
                  width="300"
                  height="2"
                  viewBox="0 0 300 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="elastic-line-email"
                    d="M0 0.999512C0 0.999512 60.5 0.999512 150 0.999512C239.5 0.999512 300 0.999512 400 0.999512"
                    stroke="#D1D4DA"
                    strokeWidth="2"
                  />
                </svg>
                {isValidEmail ? (
                  <span className="errors">Email is Invalid</span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-container">
                <p className="placeholder placeholder-phone">Phone Number</p>
                <input
                  type="tel"
                  value={formData.phoneNumber}
                  name="phoneNumber"
                  autocomplete="off"
                  className="input-number input"
                  onFocus={handlePhoneNumberChange}
                  onBlur={phoneNumberFocusOut}
                  onChange={inputChange}
                />
                <svg
                  className="line-svg"
                  width="300"
                  height="2"
                  viewBox="0 0 300 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="elastic-line-phone"
                    d="M0 0.999512C0 0.999512 60.5 0.999512 150 0.999512C239.5 0.999512 300 0.999512 400 0.999512"
                    stroke="#D1D4DA"
                    stroke-width="2"
                  />
                </svg>
                {isValidPhoneNumber ? (
                  <span className="errors">Phone Number is Invalid</span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-container">
                <p className="placeholder placeholder-comment">Your Inquiry</p>
                <textarea
                  rows={"6"}
                  // maxLength={"50%"}
                  value={formData.message}
                  name="message"
                  autocomplete="off"
                  className="input-number"
                  onFocus={handleCommentChange}
                  onBlur={commentFocusOut}
                  onChange={inputChange}
                />
              </div>
              <div className="sendbtn">
                <button className="join-btn" onClick={handleSubmit}>
                  Send Enquiry
                </button>
              </div>
            </div>
          </div>

          <div className="contact_info">
            <div className="left_info">
              <p>Nepserv Consults Limited</p>
              <p>Plot 92, Kanjokya Street, Kanjokya House, Kampala</p>
              <p>P.O BOX 106714, Kampala, Uganda</p>
            </div>
            <div className="vertical_line"></div>
            <div className="right_info">
              <p>
                <b>Tel</b>: <FaWhatsapp />
                0393236376, <FaPhone /> 0200770500, <FaPhone /> 0740594860
              </p>
              <p>
                <b>Email</b>:{" "}
                <a href="mailto: info@nepserv.co.ug">info@nepserv.co.ug</a> |{" "}
                <a href="mailto:support@angage.co.ug">support@angage.co.ug</a>{" "}
              </p>
              <p>
                <b>Web</b>:
                <a href=" https://nepserv.co.ug/"> https://nepserv.co.ug/</a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
