//This is the Our Partners Component
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useState } from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { fetchCompanyDetails } from "../..//Services/fetchWebsiteCompanyDetailsService";
import "../../styles/OurBackers.css";
import videoBgPartners from "./partners.mp4";

gsap.registerPlugin(ScrollTrigger);

export default function OurBackers() {
  const [companyDetails, setCompanyDetails] = useState([]);
  const handleDragStart = (e) => e.preventDefault(); //for the carousel incase you dont want autoplay

  //displaying the fetched company logo
  const items = companyDetails.map((result, idx) => [
    <div className="logoDiv">
      <img
        key={idx}
        src={result.logo}
        alt=""
        className="backerlogo"
        onDragStart={handleDragStart}
        role="presentation"
      />
    </div>,
  ]);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 4 },
  };

  //calling the api to fetch company logo
  const getCompanies = async () => {
    const { data } = await fetchCompanyDetails();

    const result = data.filter((company) => company.logo !== "");

    setCompanyDetails(result);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  //animations
  useEffect(() => {
    gsap.from(".backer img", {
      x: "-20%",
      opacity: 5,
      scrollTrigger: {
        trigger: ".backers-title",
        scrub: true,
        start: "-50%",
        end: "-10%",
      },
      stagger: 0.5,
    });
  }, []);

  return (
    <div className="whole-backers-container" id="ourbackers">
      {/* backers container */}
      <div className="overlayPartners"></div>
      <video className="videoBgPartners" autoPlay loop muted>
        <source src={videoBgPartners} type="video/mp4" />
      </video>
      <div className="backers-top">
        <h2 className="backers-title">
          <strong>Our Partners</strong>
        </h2>
      </div>
      <div className="backers-contaier">
        <div className="backersInnerContainer">
          <AliceCarousel
            autoPlay
            autoPlayStrategy="none"
            autoPlayInterval={5000}
            animationDuration={1500}
            animationType="fadeout"
            infinite
            touchTracking={false}
            disableDotsControls
            disableButtonsControls
            items={items}
            responsive={responsive}
          />
        </div>
      </div>
    </div>
  );
}
