import React from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import "../styles/footer.css";

const OurFooter = () => {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="footer-content">
          {/* <div className="col">
              <h3>CONTACT US</h3>
              <p className="list-unstyled">
              Phone: +256 393236376
              <br/>
              Email: <br/> support@nepserv.co.ug <br/> info@nepserv.co.ug
              </p>
            </div>          
            
            <div className="col">
              <h3>LOCATION</h3>
              <p className="list-unstyled">
              Kyanjokya House
              <br/>
              Kanjokya Street, Kamwokya Kampala, Uganda            
              </p>
            </div>    
            
            <div className="col">
              <h3>SITE LINKS</h3>
              <p className="list-unstyled">
              Home
              <br/>
              About
              <br/>
              Services
              <br/>
              Contact Us            
              </p>
            </div>  */}
          <div className="left_info">
            <p>Nepserv Consults Limited</p>
            <p>Plot 92, Kanjokya Street, Kanjokya House, Kampala</p>
            <p>P.O BOX 106714, Kampala, Uganda</p>
          </div>
          <div className="vertical_line"></div>
          <div className="right_info">
            <p>
              <b>Tel</b>: <FaWhatsapp />
              0393236376, <FaPhone /> 0200770500, <FaPhone /> 0740594860
            </p>
            <p>
              <b>Email</b>:{" "}
              <a href="mailto: info@nepserv.co.ug">info@nepserv.co.ug</a> |{" "}
              <a href="mailto:support@angage.co.ug">support@angage.co.ug</a>
            </p>
            <p>
              <b>Web</b>:
              <a href=" https://nepserv.co.ug/"> https://nepserv.co.ug</a>
            </p>
          </div>
        </div>
        <hr
          style={{
            borderTop: " 0.5px solid rgba(255, 255, 255, 0.56)",
            backdropFilter: "blur(2rem)",
            borderBottom: " 0.5px solid rgba(255, 255, 255, 0.56)",
          }}
        />
        <div className="row2">
          <p className="col-sm">
            &copy;{new Date().getFullYear()} | All Rights Reserved | Powered by{" "}
            <a href="https://www.nepserv.co.ug/">NEPSERV Consults Limited</a>
            {/* <img src={logo} alt="Nepserve" className="nepservlogo" /> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurFooter;
