import IntroSection from "./components/intro-section/IntroSection";
import "./App.css";
import OurProducts from "./components/patners/OurProducts";
import WhyCustomerEng from "./components/WhyCustomerEng";
import OurFooter from "./components/OurFooter";
import OurBackers from "./components/OurBackers/OurBackers";
import React from "react";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <>
        <div className="page-container">
          <div className="content-wrap">
            <IntroSection />
            <WhyCustomerEng />
            <OurProducts />
            <OurBackers />
          </div>
          <OurFooter />
        </div>
      </>
    </BrowserRouter>
  );
}

export default App;
