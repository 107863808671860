import url from "../config.json";
import http from "./httpServiceForCompaniesFromEngine";

const companyDetailUrl = url.url + "company";

export const fetchCompanyDetails = async () => {
  const addUrl = companyDetailUrl + "/get-company-details-for-website";

  const { data } = await http.get(addUrl);

  return data;
};
