import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import "../styles/why-customer-eng.css";
import videoBackground from "./services.mp4";
import process from "./why-customer-eng/dataproc.avif";
import service from "./why-customer-eng/handshake.avif";
import monitor from "./why-customer-eng/productdevelopment.avif";
import sale from "./why-customer-eng/salesmanagement.avif";
import manage from "./why-customer-eng/systemmonitoring.avif";
import ticket from "./why-customer-eng/tick.avif";

gsap.registerPlugin(ScrollTrigger);

export default function WhyCustomerEng() {
  useEffect(() => {
    const tlPartners = gsap.timeline({
      duration: 1,
    });
    gsap.from(".cta-2", {
      x: "-100%",
      scrollTrigger: {
        // trigger: ".why-customer-eng-container",
        start: "-40%",
        end: "-10%",
        scrub: true,
      },
    });
    tlPartners.from(".wc1-img", {
      x: "100%",
      scrollTrigger: {
        // trigger: ".why-customer-eng-container",
        start: "-40%",
        end: "0%",
        scrub: true,
      },
    });
    tlPartners.from(".wc2-img", {
      x: "100%",
      scrollTrigger: {
        // trigger: ".why-customer-eng-container",
        start: "-40%",
        end: "0%",
        scrub: true,
      },
    });
    tlPartners.from(".wc3-img", {
      x: "-100%",
      scrollTrigger: {
        start: "-40%",
        end: "0%",
        scrub: true,
      },
    });
    tlPartners.from(".wc4-img", {
      x: "-100%",
      scrollTrigger: {
        start: "-40%",
        end: "0%",
        scrub: true,
      },
    });
  }, []);

  return (
    <div className="why-customer-eng-container">
      <div className="why-header">
        <h2 className="why-title">
          {/* <span className="cta-1"> </span>  */}
          <b>Our Services</b>
        </h2>
      </div>
      <div className="why-backers-container">
        <div className="overlaywhy"></div>
        <video className="videoBgwhy" autoPlay loop muted>
          <source src={videoBackground} type="video/mp4" />
        </video>
        <div className="why-card">
          <div className="why-card-content">
            <div className="why-card-top">
              <img src={ticket} className="" />
            </div>
            <div className="why-card-bottom">
              <h3 className="bottom-title"> Ticket Management </h3>
              <p className="bottom-desc">
                Manage all company and product related issues and tickets from
                one central place
              </p>
            </div>
          </div>
        </div>
        <div className="why-card">
          <div className="why-card-content">
            <div className="why-card-top">
              <img src={service} className="" />
            </div>
            <div className="why-card-bottom">
              <div>
                <h3 className="bottom-title"> Service Extension </h3>
              </div>
              <div>
                <p className="bottom-desc">
                  {/* Just like you would download Tor Browser to get access to Deep
                  Web, you need Point Browser, next-gen software to get access to
                  real web3. */}
                  Enhance support through WhatsApp BOT automations, Email
                  integrations, website integrations and phone call integrations
                  to deliver the best customer service to your clients.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="why-card">
          <div className="why-card-content">
            <div className="why-card-top ">
              <img src={process} className="" />
            </div>
            <div className="why-card-bottom">
              <div>
                <h3 className="bottom-title">
                  Streamlined Data processing{/*Sales tracking*/}
                </h3>
              </div>
              <div>
                <p className="bottom-desc">
                  Save time, money while improving efficiency of operations
                  through the enhanced data processing tool accessible from
                  anywhere at any time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="why-card">
          <div className="why-card-content">
            <div className="why-card-top">
              <img src={sale} className="" />
            </div>
            <div className="why-card-bottom">
              <div>
                <h3 className="bottom-title">
                  Robust sales management{/*Payments tracking*/}{" "}
                </h3>
              </div>
              <div>
                <p className="bottom-desc">
                  {/* Just like you would download Tor Browser to get access to Deep
                Web, you need Point Browser, next-gen software to get access to
                real web3. */}
                  Better track prospects, targets, field activity through a
                  fully built sales management module
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="why-card wc5-img">
          <div className="why-card-top">
            <img src="../images/why-customer-eng/4.jpg" />
          </div>
          <div className="why-card-bottom">
            <h3 className="bottom-title">Payments tracking </h3>
            <p className="bottom-desc">
              Just like you would download Tor Browser to get access to Deep
              Web, you need Point Browser, next-gen software to get access to
              real web3.
            </p>
          </div>
        </div>*/}
        {/*<div className="why-card wc6-img">
          <div className="why-card-top">
            <img src="../images/why-customer-eng/5.jpg" />
          </div>
          <div className="why-card-bottom">
            <h3 className="bottom-title">Tracking institutions setup</h3>
            <p className="bottom-desc">
              Just like you would download Tor Browser to get access to Deep
              Web, you need Point Browser, next-gen software to get access to
              real web3.
            </p>
          </div>
      </div>*/}
        <div className="why-card">
          <div className="why-card-content">
            <div className="why-card-top">
              <img src={monitor} />
            </div>
            <div className="why-card-bottom">
              <h3 className="bottom-title">
                Project Management & Product Development
              </h3>
              <p className="bottom-desc">
                Track the performance of all your projects, tasks and sub tasks
                better so as to deliver projects against the best quality and
                time expectations. Developed in compliance with ISO 9001:2015 &
                ISMS 27001 Information security acceptable standards.
              </p>
            </div>
          </div>
        </div>
        <div className="why-card">
          <div className="why-card-content">
            <div className="why-card-top">
              <img src={manage} />
            </div>
            <div className="why-card-bottom">
              {/* /why-customer-eng/productdevelopment.jpg */}
              <h3 className="bottom-title">
                System Uptime monitoring{/*Tracking institutions setup*/}
              </h3>
              <p className="bottom-desc">
                {/* Just like you would download Tor Browser to get access to Deep
                Web, you need Point Browser, next-gen software to get access to
                real web3. */}
                The easiest way to monitor system’s availability so as to serve
                your customers and clients better.
              </p>
            </div>
          </div>
        </div>
        {/* backers container */}
        {/*<div className="backers-contaier">
          <div className="backers-top">
            <h2 className="backers-title">
              <strong>Partners</strong>
            </h2>
            <p className="backers-subtitle">
              Companies and funds that believe in our mission and claimed a
              stake in Customer Engine's success.
            </p>
          </div>
          <div className="backers-bottom">
            <div className="backer">
              <img src="../images/why-customer-eng/nep.png" className="" />
            </div>
            <div className="backer">
              <img src="../images/why-customer-eng/neptune.png" className="" />
            </div>
            <div className="backer">
              <img
                src="../images/why-customer-eng/scops_logo-1.png" className="" />
            </div>
            <div className="backer">
              <img src="../images/why-customer-eng/ftx.svg" className="" />
            </div>
            <div className="backer">
              <img
                src="../images/why-customer-eng/healthpal.svg"
                className=""
              />
            </div>
            <div className="backer">
              <img src="../images/why-customer-eng/seed.svg" className="" />
            </div>
            <div className="backer">
              <img src="../images/why-customer-eng/sino.svg" className="" />
            </div>
            <div className="backer">
              <img src="../images/why-customer-eng/neptune.png" className="" />
            </div>
            <div className="backer">
              <img
                src="../images/why-customer-eng/scops_logo-1.png"
                className=""
              />
            </div>
            <div className="backer">
              <img src="../images/why-customer-eng/def.svg" className="" />
            </div>
          </div>
        </div>*/}
      </div>
    </div>
  );
}
