import url from "../config.json";
import http from "./httpServiceForCompaniesFromEngine";

const productDetailsUrl = url.url + "products";

export const fetchProductDetails = async () => {
  const addUrl =
    productDetailsUrl + "/fetch-product-details-for-website-display";

  const { data } = await http.get(addUrl);

  return data;
};
