//This component was initially a partners component by later was changed to a  products component.
//Take note not to confuse where the elements are labelled "partners" for the "OurBackers"
//component

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { fetchProductDetails } from "../../Services/fetchWebsiteProductDetailsService";
import "../../styles/OurProducts.css";
gsap.registerPlugin(ScrollTrigger);

export default function OurProducts() {
  const introRef = useRef(null);
  const partnersContner = useRef(null);
  const handleDragStart = (e) => e.preventDefault();
  const [productDetails, setProductDetail] = useState([]);

  //passing the fetched product logo and website to the AliceCarousel for display
  const items = productDetails.map((product, idx) => [
    <div className="logoDiv">
      <a href={product.product_website} target="_blank" rel="noreferrer">
        <img
          key={idx}
          src={product.logo}
          alt="product logo"
          className="productlogo"
          onDragStart={handleDragStart}
          role="presentation"
        />
      </a>
    </div>,
  ]);

  //responsiveness of the AliceCarousel
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 4 },
  };

  //calling api to fetch products from the customer engine core database

  const getProducts = async () => {
    const { data } = await fetchProductDetails();

    const result = data.filter((product) => product.logo !== "");

    setProductDetail(result);
  };

  useEffect(() => {
    getProducts();
  }, []);

  //animations

  useEffect(() => {
    const el = introRef.current;

    const tlPartners = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        scrub: true,
      },
      ease: "linear",
    });

    gsap.set(el, { transformOrigin: "center" });
    tlPartners.fromTo(
      el,
      { y: "150%" },
      {
        y: 0,
        scrollTrigger: {
          trigger: ".partners-container",
          start: "-50%",
          end: "10%",
        },
      },
      "<"
    );
    // animate cards
    gsap.fromTo(
      ".healthpal",
      { y: "150%" },
      {
        y: "0%",
        scrollTrigger: {
          trigger: ".partners-container",
          start: "-40%",
          end: "10%",
        },
        stagger: 0.5,
      },
      "<"
    );
    //second-line
    gsap.fromTo(
      ".second-line",
      { y: "100%" },
      {
        y: "0%",
        scrollTrigger: {
          trigger: ".partners-container",
          start: "0%",
          end: "50%",
        },
        stagger: 0.2,
      }
    );
  }, []);

  return (
    <div ref={partnersContner} className="partners-container" id="Partners">
      <div className="partners-intro">
        <h1 className="intro-title">Product Service Stack</h1>
      </div>
      <div className="partners-inner-container">
        <div className="productcards">
          <AliceCarousel
            autoPlay
            className="slider"
            // autoWidth
            // autoHeight="false"
            autoPlayStrategy="none"
            autoPlayInterval={1000}
            animationDuration={1500}
            animationType="fadeout"
            infinite
            touchTracking={false}
            disableDotsControls
            disableButtonsControls
            items={items}
            responsive={responsive}
          />
        </div>
      </div>
    </div>
  );
}
